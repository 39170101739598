import { Box, Select, SelectProps } from "@amzn/awsui-components-react";
import { Label } from "aws-amplify-react";

export interface NameDisplay {
    alias: string;
    name: string;
}

/*
    SingleUseSelect is intended to be used with a parent that will be keeping track
    of item changes at the top level. Syncing only needs to be used if using this
    component in a list of duplicate components and they can be individually deleted.

    If not using 'sync' functionality, then pass in {syncing: false, resetSyncing: () => {}}
*/

export interface SingleUseSelectProps extends SelectProps {
    id: string
    label: string
    secondaryLabel: string;
    selectOptions: NameDisplay[];
    index: number;
    disabled: boolean;
    onSelectionChangeNotifyAttrs:(label: string, value: string, index: number) => void;
}

export const SingleUseSelect = (props: SingleUseSelectProps) => {
    
    function selectionChanged(detail) {
        let label: string = detail.selectedOption.label ? detail.selectedOption.label : '';
        let value: string = detail.selectedOption.value ? detail.selectedOption.value : '';
        props.onSelectionChangeNotifyAttrs(label, value, props.index);
    }

    function getName(user: NameDisplay): string {
        return user.name ? `${user.name}, ${user.alias}` : user.alias;
    }

    function createSelectionOptions(): { label: string, value: string}[] {
        let options: { label: string, value: string }[] = [];
        props.selectOptions.forEach((resource, index, array) => {
            options.push({label: getName(resource), value: resource.alias});
        });

        return options;
    }

    return (
        <Box>
            <Label>{`${props.label}`}<br/><span style={{color: 'gray', fontSize: 10}}>{`${props.secondaryLabel}`}</span></Label>
            <Select
            id={props.id}
            selectedOption={props.selectedOption}
            onChange={({ detail }) => selectionChanged(detail) }
            options={createSelectionOptions()}
            placeholder={props.placeholder}
            empty={props.empty}
            disabled={props.disabled}
            />
        </Box>
    );
}