import {isProd} from '../../helpers/utils';
import {Engagement} from "../types";
import { AnalyticsEvent } from './analyticsEvent';

export let pinpointLinkDataAttrs = (engagement: Engagement, event: AnalyticsEvent) => `engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${event}`
export const CTI_LINK = isProd ? 'https://cti.amazon.com' : 'https://cti-test-iad.iad.proxy.amazon.com';
export const Salesforce_LINK = (sfdcId: string) => `https://proservecrm.lightning.force.com/lightning/r/${sfdcId}/view`
export const AESA_PSQ_LINK = (eng: Engagement) => {
    const id = eng.id.endsWith("-N") ? eng.projectId : eng.id;
    const integ = isProd ? "" : "-integ";
    const labelQuery = "11bc4966-6c1e-413e-80e6-3f2b80b1c4e3" // this ID is only valid in prod, until we find the correct ID in integ, the filter will be invalid in integ
    return `https://issues${integ}.amazon.com/issues/search?q=label%3A(${labelQuery})+customFields.string.salesforce_project_id%3A(${id})`
}

export const ALL_OPEN_SIM_C_LINK = (eng: Engagement) => {
    const id = eng.id.endsWith("-N") ? eng.projectId : eng.id;
    const integ = isProd ? "" : "-integ";
    return `https://issues${integ}.amazon.com/issues/search?q=customFields.string.salesforce_project_id%3A(${id})+status%3A(Open)&sort=score+desc`
}

// RG link uses Engagement ID for both pre- and post-migration tickets, since the engagement ID always matches the resolver group created by ESAC
export const RG_Tickets_LINK = (engId: string) => {
    const integ = isProd ? "" : "-integ";
    return `https://t${integ}.corp.amazon.com/issues/?q=extensions.tt.status%3A%28Assigned%20OR%20Researching%20OR%20%22Work%20In%20Progress%22%20OR%20Pending%29%20AND%20extensions.tt.assignedGroup%3A%22${engId}%22`
}
export const DSR_LINK = (eng: Engagement) => {
    const id = eng.id.endsWith("-N") ? eng.projectId : eng.id;
    const integ = isProd? "" : "-integ";
    const folderQuery = "09c53aee-5e05-493d-95b5-5ac712cadce0" // this ID is only valid in prod, until we find the correct ID in integ, the filter will be invalid in integ
    return `https://issues${integ}.amazon.com/issues/search?q=containingFolder%3A(${folderQuery})+customFields.string.salesforce_project_id%3A(${id})`
}
export const SSP_LINK = (eng: Engagement) => {
    const id = eng.id.endsWith("-N") ? eng.projectId : eng.id;
    const integ = isProd? "" : "-integ";
    const folderQuery = "445b3c9f-9a82-442e-b27d-432c676ef16c" // this ID is only valid in prod, until we find the correct ID in integ, the filter will be invalid in integ
    return `https://issues${integ}.amazon.com/issues/search?q=containingFolder%3A(${folderQuery})+customFields.string.project_id%3A(${id})`
}

export const BADGE_PHOTO_LINK = (alias: string) => {
    return `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${alias}`;
}

export const PHONETOOL_LINK = (alias: string | undefined) => {
    if (alias) {
        return `https://phonetool.amazon.com/users/${alias}`;
    }
    return `https://phonetool.amazon.com`;
}

export const IS_IT_DOWN_LINK = 'https://is-it-down.amazon.com/aws-ace-esp.json';

export const SALESFORCE_PARTNER_LINK = (id: string) => `https://proservecrm.lightning.force.com/lightning/r/Partner_Account_Involved__c/${id}/view`;

export const enum MODE {
    EDIT = 'edit',
    VIEW = 'view'
}