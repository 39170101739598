import { FlashbarProps, Link } from '@amzn/awsui-components-react';
import { useEffect, useMemo, useState, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { EngagementDetail } from '../components/engagement-detail';
import ServiceAppLayout from '../components/ServiceAppLayout';
import { NameDisplay } from '../components/shared/single-use-select';
import { ALTERNATE_ENGAGEMENT_FLASHBAR, DEFAULT_ERROR_FLASHBAR, ENGAGEMENT_LOAD_ERROR_FLASHBAR, RG_LOAD_ERROR_FLASHBAR } from '../core/constants';
import { Engagement } from '../core/types';
import { EsacRGMember, RGMember } from '../core/types/RGMember';
import { EngagementDetailPageContext } from './EngagementDetailPageContext';
import { getOpenTicketsCountFromEngagementId } from "../helpers/tickets";
import { Workflows } from "../core/workflows";

const emptyEngagement: Engagement = {
  id: '',
  projectId: '',
  sfdcId: '',
  manager: '',
  managerFullName: '',
  managerReportsTo: '',
  name: '',
  customerName: '',
  customerId: '',
  startDate: '',
  endDate: '',
  city: '',
  region: '',
  resources: [],
  names: [],
  titles: [],
  additionalResolverGroups: [],
};

const EngagementDetailPage = (props: RouteComponentProps) => {
  const [engagement, setEngagement] = useState<Engagement>(emptyEngagement);
  const [loading, setLoading] = useState<boolean>(true);
  const [rgData, setRgData] = useState<RGMember[]>([]);
  const { setNotifications, appendNotification } = useAppContext();

  // states used to poll in-flight workflows
  // workflows poll duration set to 4 minutes
  const pollDuration = () => { return Date.now() + (60000 * 4) }
  const [workflowPollTimeout, setWorkflowPollTimeout] = useState(pollDuration)
  const resetPollingTimeout = () => { setWorkflowPollTimeout(pollDuration) }
  const endPolling = () => { setWorkflowPollTimeout(1) }

  // Keeps track of resources already in RG and resources that have been added in the AddResourcesModal
  const [usedResources, setUsedResources] = useState<string[]>([]);
  const { client } = useAppContext();

  function addToUsed(value: string | undefined) {
    if (value) {
      usedResources.push(value);
      setUsedResources([...usedResources]);
    }
  }

  function removeFromUsed(value: string | undefined) {
    if (value) {
      let findResult = usedResources.findIndex((arrayValue, index, obj) => {
        return arrayValue === value;
      });
      if (findResult !== -1) {
        usedResources.splice(findResult, 1);
        setUsedResources([...usedResources]);
      }
    }
  }

  function getRgData(): RGMember[] {
    return rgData;
  }

  function getRemainingResources(): NameDisplay[] {
    let remainingResources: NameDisplay[] = [];
    if (engagement) {
      engagement.resources.forEach((value, index, array) => {
        let findResult = usedResources.some((usedValue, usedIndex, usedArray) => {
          return value === usedValue;
        });

        if (!findResult) {
          // Find full name for alias
          let fullName: string | undefined;
          if (value === engagement.manager) {
            fullName = engagement.managerFullName;
          } else if (value === engagement.securityBarRaiser) {
            fullName = engagement.sbrFullName;
          } else if (value === engagement.deliveryPracticeManager) {
            fullName = engagement.dpmFullName;
          } else if (value === engagement.opportunityOwner) {
            fullName = engagement.opportunityOwnerFullName;
          } else {
            // Filter out special engagement roles before searching resources array for name
            let filteredResources: string[] = engagement.resources.filter((filterValue, filterIndex, filterArray) => {
              return (filterValue !== engagement.manager && filterValue !== engagement.securityBarRaiser && filterValue !== engagement.deliveryPracticeManager && filterValue != engagement.opportunityOwner)
            });
            filteredResources.forEach((filteredValue, filteredIndex, filteredArray) => {
              if (filteredValue === value && engagement.names) {
                fullName = engagement.names[filteredIndex];
              }
            })
          }

          remainingResources.push({ alias: value, name: fullName as string });
        }
      });
    }

    return remainingResources;
  }

  useEffect(() => {
    setNotifications([]);
    // on page load, clear the expired workflows
    // only do this once. Do not clear workflows already being polled upon
    const workflows = new Workflows(engagement.id)
    workflows.clearExpired()
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const loadedEngagement: Engagement = await client.get(`/ts/engagements/${props.match.params.id}`) as Engagement;

      if (loadedEngagement.activeCTI !== undefined) {
        const openTicketsResponse = await getOpenTicketsCountFromEngagementId(client, loadedEngagement.id);
        if (Number.isInteger(openTicketsResponse)) {
          loadedEngagement.openTickets = openTicketsResponse as number;
        }
      }

      setEngagement(loadedEngagement);
      if (loadedEngagement.alternateEngagement) {
        appendNotification({
          content: (
            <Fragment>
              {`${ALTERNATE_ENGAGEMENT_FLASHBAR}`}
              <Link
                color="inverted"
                href={`/engagements/${loadedEngagement.alternateEngagement.projectId}`}
              >
                <b>{`${loadedEngagement.alternateEngagement.name} ${loadedEngagement.alternateEngagement.projectId}`}</b>
              </Link>
            </Fragment>
          ),
          type: 'info',
          dismissible: true,
          dismissLabel: 'Dismiss message',
        });
      }

      // Get RG members
      if (loadedEngagement.activeCTI !== undefined) {
        const loadedRgData: EsacRGMember[] = await client.get(`/ts/rg/${loadedEngagement.id}`) as EsacRGMember[];
        let frontendRgData: RGMember[] = [];
        let usedAliases: string[] = [];
        loadedRgData.forEach((value, index, array) => {
          // Craft RGMember object
          let member: RGMember = {
            alias: value.alias,
            fullName: value.fullName,
            title: value.title,
            role: value.role,
            support_order: value.support_order,
            pager_email: `page-${value.alias}@amazon.com`,
            selection_label: value.alias,
            selection_value: value.alias
          };
          frontendRgData.push(member);

          // Fill out already used resources for future use
          usedAliases.push(value.alias);
        });

        // Add delivery practice manager to used resources as the DPM cannot
        // be added as a member to the RG
        if (loadedEngagement.deliveryPracticeManager) {
          usedAliases.push(loadedEngagement.deliveryPracticeManager)
        }

        setRgData(frontendRgData);
        setUsedResources(usedAliases);
      }

      setLoading(false);
    } catch (error: any) {
      let errorMessage: any = DEFAULT_ERROR_FLASHBAR;
      let type: FlashbarProps.Type = 'error';
      if (error?.response.status === 404) {
        if (error.response.data?.projectId) {
          errorMessage = (
            <Fragment>
              {`${ALTERNATE_ENGAGEMENT_FLASHBAR}`}
              <Link
                color="inverted"
                href={`/engagements/${error.response.data.projectId}`}
              >
                <b>{`${error.response.data.name} ${error.response.data.projectId}`}</b>
              </Link>
            </Fragment>
          );
          type = 'info';
        } else {
          errorMessage = ENGAGEMENT_LOAD_ERROR_FLASHBAR;
        }
      } else if (error?.response.status === 502) {
        // IGNORE, HANDLED BY DISPLAYING MESSAGE IN RG TAB
        setLoading(false);
        return;
      }
      appendNotification({
        content: errorMessage,
        type,
        dismissible: true,
        dismissLabel: 'Dismiss message',
      });
      setLoading(false);
    }
  }

  async function refreshResources() {
    setLoading(true);
    await loadData();
  }

  let breadcrumbs = useMemo<any[]>(() => {
    let breadcrumbItems: any[] = [];
    if (!engagement) {
      breadcrumbItems.push({
        text: props.match.params.id,
        href: `/${props.match.params.id}`
      });
    }
    else {
      breadcrumbItems.push({
        text: engagement.name,
        href: `/${engagement.id}`
      });
    }
    return breadcrumbItems;
  }, [engagement, props.match.params.id]);

  return (
    <EngagementDetailPageContext.Provider value={{ engagement, setEngagement, workflowPollTimeout, resetWorkflowPollTimeout: resetPollingTimeout, endWorkflowPolling: endPolling }}>
      <ServiceAppLayout
        className='engagement-detail-page'
        page='EngagementDetailPage'
        breadcrumbItems={breadcrumbs}
        content={<EngagementDetail {...{
          engagement,
          loading,
          refreshResources: refreshResources,
          loadData: loadData,
          addToUsedResources: addToUsed,
          getRemainingResources: getRemainingResources,
          getRgData: getRgData,
          removeFromUsedResources: removeFromUsed,
        }}></EngagementDetail>}
      />
    </EngagementDetailPageContext.Provider>
  );
}

export default withRouter(EngagementDetailPage);
