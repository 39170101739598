import { Box, Input } from "@amzn/awsui-components-react";
import { Label } from "aws-amplify-react";

/*
    EPDisabledInput is a very generic Input with a label that will always be disabled
    to user input.
*/

export interface EPDisabledInputProps {
    id: string;
    value: string;
    label: string;
    secondaryLabel: string;
}

export const EPDisabledInput = (props: EPDisabledInputProps) => {

    return (
        <Box>
            <Label>{`${props.label}`}<br/><span style={{color: 'gray', fontSize: 10}}>{`${props.secondaryLabel}`}</span></Label>
            <Input id={props.id} value={props.value} disabled={true}/>
        </Box>
    );
}