import {
    Box,
    Table,
    Button,
    SpaceBetween,
    Alert,
    Spinner,
    Link,
    Header,
    Icon
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { EngagementDetailProps } from '..';
import { User } from "../../../core/user/index"
import { AddResourcesModal } from './AddResourcesModal';
import { Fragment, useMemo, useState } from 'react';
import { EditSupportOrderModal } from './EditSupportOrderModal';
import { ConfirmationModal, EmployeePhoto } from '../../shared';
import { AnalyticsEvent, AnalyticsEventType, PHONETOOL_LINK, RG_MEMBER_REMOVE_FLASHBAR_TEXT, UNDEFINED_VALUE } from '../../../core/constants';
import { UpdateResolverGroupRequest } from "../../../core/types/UpdateResolverGroupRequest";
import './ResourcesTab.css';
import { RGMember } from '../../../core/types/RGMember';
import { useAppContext } from '../../../AppContext';
import { isEngagementDeleteable } from '../../../core/utils/engagement-utils/EngagementUtils';
import { useEngagementDetailPageContext } from '../../../pages/EngagementDetailPageContext';
import { ResourceDeletionModal } from '../../shared/resource-deletion-modal';
import { ConfigurationContainer } from './ConfigurationContainer';
import { Workflows } from "../../../core/workflows";
import { StartWorkflowResponse } from "../../../core/types/StartWorkflowResponse";
import {AxiosError} from "axios";

export interface SortDefinition {
    sortDesc: boolean;
    sortCol: any;
}

export interface ResourcesTabProps extends EngagementDetailProps {
    displayRequestError: (errorMessage: string) => void;
    confirmDeletion: () => void;
}

export const ResourcesTab = (props: ResourcesTabProps) => {
    async function removeResources() {
        if (!collectionProps.selectedItems) return;

        const emDeleted = collectionProps.selectedItems.find(member => member.alias === props.engagement.manager);

        if (emDeleted) {
            setBadRemoveVisible(true);
            setRemoveModalVisible(false);
            return;
        }

        setModalLoading(true);
        const requestBody: UpdateResolverGroupRequest = {
            engagementId: props.engagement.id,
            name: props.engagement.primaryResolverGroup!,
            deletedMembers: collectionProps.selectedItems!.map(member => ({
                alias: member.alias,
            })),
        };

        try {
            const workflowId = await client.patch(`/ts/rg`, requestBody) as StartWorkflowResponse;
            if (featureFlags["workflow_status"]?.enabled) {
                Workflows.addWorkflow(props.engagement.id, workflowId.workflow_id, {remove: collectionProps.selectedItems.length})
                resetWorkflowPollTimeout()
            } else {
                setNotifications([{
                    header: 'Success',
                    content: RG_MEMBER_REMOVE_FLASHBAR_TEXT(collectionProps.selectedItems!.length),
                    type: 'success',
                    dismissible: true,
                    dismissLabel: 'Dismiss message',
                }]);
                await props.refreshResources();
            }
        } catch (e) {
            const error = e as AxiosError
            props.displayRequestError(error.message)
        }

        setModalLoading(false);
        setRemoveModalVisible(false);
    }

    function buttonsDisabled(): boolean {
        if (collectionProps.selectedItems &&
            collectionProps.selectedItems.length > 0 &&
            !props.loading) {
            return false;
        }

        return true;
    }

    function getName(resource: RGMember): string | undefined {
        if (resource.alias === props.engagement.manager) {
            return props.engagement.managerFullName;
        } else if (resource.alias === props.engagement.deliveryPracticeManager) {
            return props.engagement.dpmFullName;
        } else if (resource.alias === props.engagement.securityBarRaiser) {
            return props.engagement.sbrFullName;
        } else if (resource.alias === props.engagement.opportunityOwner) {
            return props.engagement.opportunityOwnerFullName;
        }

        return resource.fullName;
    }

    function getRole(resource: RGMember): string | undefined {
        if (resource.alias === props.engagement.manager) {
            return 'Engagement Manager';
        } else if (resource.alias === props.engagement.deliveryPracticeManager) {
            return 'Delivery Practice Manager';
        } else if (resource.alias === props.engagement.securityBarRaiser) {
            return 'Security Bar Raiser';
        } else if (resource.alias === props.engagement.opportunityOwner) {
            return 'Opportunity Owner';
        }

        // Query could potentially have title data as '~' as null placeholder
        if (!resource.title || resource.title === '~') {
            return UNDEFINED_VALUE;
        }

        return resource.title;
    }

    // State variables
    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(false);
    const [badRemoveVisible, setBadRemoveVisible] = useState<boolean>(false);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
    const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [backgroundUpdate, setBackgroundUpdate] = useState<boolean>(false);
    const { client, featureFlags, setNotifications } = useAppContext();
    const { engagement, resetWorkflowPollTimeout } = useEngagementDetailPageContext();

    useMemo(() => {
        setRefreshDisabled(props.loading);
        if (!props.loading) {
            setBackgroundUpdate(true);
        }
    }, [props.loading])

    // Define columns for table
    const colDefs = [
        {
            id: 'photo',
            header: '',
            cell: resource => <EmployeePhoto alias={resource.alias} />,
            width: 40
        },
        {
            id: 'members',
            header: 'Members',
            cell: resource => {
                const name = getName(resource);
                return <Box id={`row-${resource.alias}`}>
                    <Link
                        href={PHONETOOL_LINK(resource.alias)}
                        external={true}
                        data-csm-on='click'
                        data-csm-name={AnalyticsEventType.LinkClick}
                        data-csm-attrs={`engagement_id:${props.engagement.projectId},segment:${props.engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}
                    >
                        {name ? `${name}, ${resource.alias}` : resource.alias}
                    </Link>
                </Box>
            },
            sortingField: 'fullName'
        },
        {
            id: 'title',
            header: 'Title',
            cell: resource => getRole(resource),
            sortingField: 'title'
        },
        {
            id: 'supportOrder',
            header: 'Support Order',
            cell: resource => resource.support_order,
            sortingField: 'support_order'
        },
        {
            id: 'pagerEmail',
            header: 'Pager Email',
            cell: resource => resource.pager_email
        }
    ]

    // Get all default settings for table (sorting, selection, etc.)
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        props.getRgData(),
        {
            filtering: {},
            sorting: {},
            selection: {},
        }
    );

    // Allow selection and edit if user is the manager for this engagement
    const user: User = new User();
    let selectionSetting = {};
    let editButtonsVisible: boolean = false;
    if ((user.userId as string) === props.engagement.manager && props.engagement.activeCTI) {
        selectionSetting = {
            selectionType: 'multi'
        }
        editButtonsVisible = true;
    }

    const modals = <Box>
        <AddResourcesModal
            loading={modalLoading}
            setLoading={setModalLoading}
            engagementSfdcId={props.engagement.sfdcId}
            engagementId={props.engagement.id}
            projectId={props.engagement.projectId}
            // This primary resolver group could in fact be undefined whenever the resolver group doesn't exist yet.
            // We shouldn't get to this point in the UI though where we are editing resolver group members though since the
            // EngagementDetailPage has error checking already in the case of a resolver group not existing yet
            resolverGroup={props.engagement.primaryResolverGroup!}
            segment={props.engagement.segment}
            getRgData={props.getRgData}
            visible={addModalVisible}
            setVisibility={setAddModalVisible}
            refreshResources={props.refreshResources}
            addToUsedResources={props.addToUsedResources}
            getRemainingResources={props.getRemainingResources}
            removeFromUsedResources={props.removeFromUsedResources}
            displayRequestError={props.displayRequestError} />
        <EditSupportOrderModal
            loading={modalLoading}
            setLoading={setModalLoading}
            engagementId={props.engagement.id}
            projectId={props.engagement.projectId}
            resolverGroup={props.engagement.primaryResolverGroup!}
            segment={props.engagement.segment}
            getRgData={props.getRgData}
            refreshResources={props.refreshResources}
            setEditModalVisible={setEditModalVisible}
            visible={editModalVisible}
            resourcesToEdit={collectionProps.selectedItems ? [...collectionProps.selectedItems] : []}
            displayRequestError={props.displayRequestError} />
        <ConfirmationModal
            loading={modalLoading}
            setVisibility={setRemoveModalVisible}
            visible={removeModalVisible}
            analyticsAttributes={{
                engagementId: props.engagement.projectId,
                segment: props.engagement.segment,
                analyticsEvent: AnalyticsEvent.RemoveResourcesButton
            }}
            confirmAction={removeResources}
            cancelAction={() => setRemoveModalVisible(false)}
            title='Confirm Removal'
            message={`Are you sure you want to remove ${collectionProps.selectedItems?.length || 0} member${collectionProps.selectedItems?.length === 1 ? '' : 's'}? Note: This operation can take up to 30 seconds.`}
        />
    </Box>;

    // Display refresh button - or display spinner
    let refreshButton = <Button
        onClick={async () => await props.refreshResources()}
        iconName={refreshDisabled ? undefined : 'refresh'}
        disabled={refreshDisabled}
    >
        {refreshDisabled && <Spinner />}
    </Button>

    let engagementClosure = <ResourceDeletionModal
                                confirm={props.confirmDeletion}
                                engagement={engagement}
                                isRenderedAsIcon={false}
                                ctiName={`AWS Professional Services → ${engagement.segment} → ${engagement.primaryResolverGroup}`}
                            />
    let buttons =   < SpaceBetween size='xxxs'>
                        { featureFlags['engagement_closure']?.enabled && isEngagementDeleteable(user.userId, engagement) &&
                            engagementClosure
                        }
                    </SpaceBetween>;
    if (editButtonsVisible) {
        buttons = <SpaceBetween direction='horizontal' size='xs'>
            <Button disabled={buttonsDisabled()} onClick={(event) => {
                setModalLoading(false);
                setRemoveModalVisible(true)
            }} variant='normal'>
                Remove
            </Button>
            <Button disabled={buttonsDisabled()} onClick={(event) => {
                setModalLoading(false);
                setEditModalVisible(true)
            }} variant='normal'>
                Edit
            </Button>
            <Button
                id='rgAddResourceButton'
                disabled={props.loading}
                onClick={(event) => {
                    setModalLoading(false);
                    setAddModalVisible(true);
                }}
                variant='primary'
            >
                Add Resource
            </Button>
            { featureFlags['engagement_closure']?.enabled && isEngagementDeleteable(user.userId, engagement) &&
                engagementClosure
            }
            {modals}
        </SpaceBetween>;
    }

    let cti: string = `AWS Professional Services → ${engagement.segment} → ${engagement.primaryResolverGroup}`;

    // Display whether our system deleted it or if it was a user
    let deletedBySystemOrUser = engagement.ctiDeletedByAlias === 'EP' ? 'our system' :  <Link
                                                                                            id='resources-tab-h2-link'
                                                                                            href={PHONETOOL_LINK(engagement.ctiDeletedByAlias)}
                                                                                            external={true}
                                                                                            data-csm-on='click'
                                                                                            data-csm-name={AnalyticsEventType.LinkClick}
                                                                                            data-csm-attrs={`engagement_id:${engagement.projectId},segment:${engagement.segment},element_type:${AnalyticsEvent.PhoneToolLink}`}>
                                                                                                {engagement.ctiDeletedByFullName}, {engagement.ctiDeletedByAlias}
                                                                                        </Link>

    return (
        <Box id="resources_tab">
            { engagement.primaryResolverGroup && engagement.activeCTI
                ?
                    <SpaceBetween size='l'>
                        <Alert visible={badRemoveVisible} dismissible={true} onDismiss={(event) => setBadRemoveVisible(false)} type="error">
                            Cannot remove Engagement Manager from resolver group.
                        </Alert>
                        <Table
                            id="resources_table"
                            {...collectionProps}
                            {...selectionSetting}
                            loading={backgroundUpdate ? false : props.loading}
                            loadingText='Loading Resources'
                            header={
                                <Header
                                    variant="h2"
                                    counter={`(${props.getRgData().length})`}
                                    actions={
                                        <SpaceBetween className='resources-table-header' direction='horizontal' size='xs'>
                                            {refreshButton}
                                            {buttons}
                                        </SpaceBetween>}
                                >
                                    Resolver Group Members
                                </Header>
                            }
                            empty={
                                <Box textAlign="center" color="inherit">
                                    No resources
                                </Box>
                            }
                            columnDefinitions={colDefs}
                            items={items}
                        />
                        {engagement.config && <ConfigurationContainer loading={props.loading} activeCTI={engagement.activeCTI} />}
                    </SpaceBetween>
                :
                    <Box id='NoRGMessage'>
                        { !props.loading &&
                            <Box variant='h2' padding='xxl'>
                                <Icon name='status-info' />
                                { engagement.ctiDeletedByAlias
                                    ?
                                        <Fragment>
                                            {' The CTI and Resolver Group for this engagement were deleted by '}{deletedBySystemOrUser}.
                                        </Fragment>
                                    :
                                        <Fragment>
                                            {" The CTI and Resolver Group for this engagement weren't created."}
                                        </Fragment>
                                }
                            </Box>
                        }
                    </Box>
            }
        </Box>
    );
}
